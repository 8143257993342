const header = {
  homepage: 'https://ferdyrahmat.my.id',
  title: 'Code.',
}

const about = {
  name: 'Ferdy Rahmat Ramdani',
  role: 'Mobile App Developer',
  description:
    'Saya adalah seorang Mobile Programmer dengan 2 tahun pengalaman, berawal dari React Native dengan TypeScript dan Redux sebelum beralih ke Flutter. Terampil menggunakan BLoC sebagai state management, menerapkan clean code, dan clean architecture untuk menghasilkan aplikasi yang optimal dan maintainable.',
  resume: '/resume.pdf',
  social: {
    linkedin: 'https://id.linkedin.com/in/ferdyrahmat',
  },
}

const projects = [
  {
    name: 'JannahKu',
    description:
      'Jannahku adalah sebuah aplikasi yang memungkinkan pengguna untuk mendapatkan pasangan hingga persiapan pernikahan serta membantu pengguna untuk mendapatkan pengetahuan mengenai pranikah, rumah tangga dan parenting.',
    stack: ['TypeScript', 'React Native', 'Redux'],
    livePreview:
      'https://play.google.com/store/apps/details?id=com.jannahku&hl=id',
  },
  {
    name: 'Qurbany',
    description:
      'Qurbany adalah sebuah aplikasi untuk Qurban secara online dan menyediakan pengguna untuk jual beli Hewan untuk di Qurbankan.',
    stack: ['TypeScript', 'React Native', 'Redux'],
    livePreview: 'https://play.google.com/store/apps/details?id=com.gmjqurbanyapp&hl=id',
  },
  {
    name: 'Hai Ortani',
    description:
      'Hai Ortani adalah sebuah aplikasi inovatif yang didedikasikan untuk membantu para petani dan pelaku pertanian dalam meningkatkan produktivitas dan efisiensi usaha pertanian mereka.',
   stack: ['TypeScript', 'React Native', 'Redux'],
    livePreview: 'https://play.google.com/store/apps/details?id=com.ortani&hl=id',
  },
  {
    name: 'LTS (LIMS)',
    description:
      'Lakaba Techno Science (LIMS) adalah aplikasi canggih yang dirancang untuk menyederhanakan dan meningkatkan efisiensi manajemen laboratorium Anda. Dengan fitur-fitur inovatif dan antarmuka yang user-friendly, aplikasi ini membantu mengelola sampel, dan proses laboratorium dengan lebih efektif.',
    stack: ['Flutter', 'Bloc', 'Get It'],
    livePreview: 'https://play.google.com/store/apps/details?id=com.gmjgloben.lims&hl=id',
  },
]

const skills = [
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'React',
  'Redux',
  'Git',
  'React Native',
  'Flutter',
  'PHP',
]

const contact = {
  email: 'matramdans@mail.com',
}

export { header, about, projects, skills, contact }
